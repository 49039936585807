$cxt-black: #191919;
$cxt-green: #00B27E;
$cxt-blue: #001529;
$cxt-lightblue: #1890ff;
$cxt-white: #FFFFFF;

p {
  text-align: start;
}

.home {
  padding: 20px;
  background-color: transparent;
  img{
    object-fit: contain;
  }
  .number {
    color: $cxt-green;
  }
}


