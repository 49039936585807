$cxt-black: #191919;
$cxt-green: #00B27E;
$cxt-blue: #001529;
$cxt-white: #FFFFFF;

.user-dropdown{
  height: 100%;
  float: right;
  font-size: 16px;
  border: none;
  background-color: $cxt-green;
}

.ant-btn {
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $cxt-white;
  outline: none;
  border: none;
  span {
    margin-left: 15px;
  }
}

.ant-dropdown-menu-item {
  text-align: center;
  width: 100%;
}

.logout-btn {
  width: 100%;
  height: 100%;
}