.comixtime-layout {
  min-height: 100vh;

  .comixtime-content-div {
    margin-top: 55px;
  }

  .comixtime-header {
    height: 55px;
    position: fixed;
    z-index: 100;
    width: 100%;
    background: #001529;
  }

  /* adjust for the filter sider
  .comixtime-content-inner-div {
    padding: 10px;
  }
  */
}