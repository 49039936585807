$cxt-black: #191919;
$cxt-green: #00B27E;
$cxt-blue: #001529;
$cxt-lightblue: #1890ff;
$cxt-white: #FFFFFF;

.ant-col {
  text-align: center;
}

.header {
  padding: 5px;
  * {
    color: $cxt-blue;
  }
}

.card-header {
  background-color: transparent;
  border: none;
  .search-field {
    border-radius: 5px;
    border: solid 1px $cxt-black;
    input {
      width: 100%;
      border: none;
      border-radius: 5px;
      padding: 2px 10px;
      color: $cxt-blue;
    }
  }
  .select-num-cards {
    border-radius: 5px;
    border: solid 1px $cxt-black;
    .ant-select-selector {
      border: none;
      border-radius: 5px;
      color: $cxt-blue;
    }
  }
}

