$cxt-black: #191919;
$cxt-green: #00B27E;
$cxt-blue: #001529;
$cxt-white: #FFFFFF;

body {
  background-image: linear-gradient($cxt-green, $cxt-blue);
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
}

.main-card {
  .text-div{
    padding: 17px;
  }
  a {
    color: $cxt-green !important;
  }
  a:hover {
    color: $cxt-blue !important;
  }
  .img-container {
    background-color: $cxt-black;
    display: flex;
    justify-content: center;
    padding: 10px 15px;
  }
  .ant-btn {
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $cxt-white;
    outline: none;
    border: none;
    svg {
      margin-right: 5px;
    }
  }
}

a {
  text-decoration: none;
  font-weight: bold;
  color: $cxt-white;
}

a:hover {
  color: $cxt-blue;
}

