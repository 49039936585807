$cxt-black: #191919;
$cxt-green: #00B27E;
$cxt-blue: #001529;
$cxt-lightblue: #1890ff;
$cxt-white: #FFFFFF;

.card {
  width: 280px;
  overflow: hidden;
  cursor: pointer;
  background-color: $cxt-white;
  .ant-card-cover {
    padding-top: 10px;
    img {
      object-fit: scale-down;
      width: auto;
      height: 100%;
    }
  }

  .ant-card-body {
    padding: 0;
  }

}

.ant-btn {
  span {
    margin: 0;
  }
}
