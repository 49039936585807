$cxt-black: #191919;
$cxt-green: #00B27E;
$cxt-blue: #001529;
$cxt-white: #FFFFFF;

body {
    background-image: linear-gradient($cxt-green, $cxt-blue);
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
}

.login-form {
    font-size: 16px;
    .input-row {
        margin-bottom: 20px;
    }
}

.register-form {
    font-size: 16px;
    text-align: left;
    .label-row {
        margin-bottom: 5px;
    }
    .input-row {
        margin-bottom: 20px;
    }
    .label {
        font-weight: bold;
    }
}

.error-span {
    color: red;
    margin-left: 8px;
}

.main-card {
    a {
        color: $cxt-green !important;
    }
    a:hover {
        color: $cxt-blue !important;
    }
    .img-container {
        background-color: $cxt-black;
        display: flex;
        justify-content: center;
        padding: 10px 15px;
    }
    .ant-btn {
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $cxt-white;
        outline: none;
        border: none;
        svg {
            margin-right: 5px;
        }
    }
}

.fb-logo {
    align-self: start;
}

.facebook-btn {
    background-color: #1877F2;
    width: 302px;
    border-radius: 5px;
}

.facebook-btn:hover {
    background-color: #1877F2;
}

.apple-button {
    height: 40px;
    width: 100%;
}

.login-btn {
    background-color: $cxt-green;
}

.login-btn:hover {
    background-color: $cxt-white;
    color: $cxt-green;
}

hr {
    margin: 10px;
}

a {
    text-decoration: none;
    font-weight: bold;
    color: $cxt-white;
}

a:hover {
    color: $cxt-blue;
}


.register {
    background: $cxt-green;
    color: $cxt-white;
    font-weight: bold;
    span {
        margin: 0;
        text-align: center;
    }
}
.register:hover {
    background: $cxt-white;
    color: $cxt-green;
    outline: none;
    border: none;
}

.botton-button {
    .ant-btn {
        span {
            margin: 0;
        }
    }
}

