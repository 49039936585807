$cxt-black: #191919;
$cxt-green: #00B27E;
$cxt-blue: #001529;
$cxt-lightblue: #1890ff;
$cxt-white: #FFFFFF;

.select-page {
  background-color: $cxt-white;
  color:$cxt-blue;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  border: solid 1px $cxt-black;
  .ant-divider{
    margin: 0 2px;
  }
  .input-nav {
    border: 0;
    background-color: transparent;
    font-size: 18px;
    max-width: 65px;
    .ant-input-number-input {
      text-align: center;
      font-weight: bold;
      color: $cxt-green;
      padding: 2px;
      .ant-input-number-focused {
        outline: none;
      }
    }
  }
  .nav-btn {
    background-color: transparent;
    span {
      color: $cxt-blue;
      font-size: 18px;
      margin: 0;
    }
    span:hover {
      color: $cxt-lightblue;
      cursor: pointer;
    }
  }

  span:hover {
    color: $cxt-lightblue;
    cursor: pointer;
  }
  .nav-btn:disabled {
    opacity: 0.6;
  }
}